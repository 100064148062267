
import React, { Component } from 'react';
import '../login/styles.css';
import { Form } from 'react-bootstrap';
import favicon from '../../assets/images/Sysco_Logo.png';
import { store } from '../..';
import { PORTAL_MESSAGE } from '../../store/actions/actionTypes';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import moment from 'moment';
import {
  INCORRECT_MESSAGE, UNAUTHORIZED_MESSAGE, NETWORK_ERROR, INACTIVEVENDOR_MESSAGE,
  LOGIN_TYPE, LOGIN_SSS
} from '../../config/string';
import { authSss } from '../../store/actions/loginAction';
import { Button, Select, Spin } from 'antd';
import { LoginOutlined, PlayCircleTwoTone } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';
import Axios from 'axios';
import { environment } from '../../common/sysco-ui-components/config/constant';
import { SUPPLIER_SUITE_URL } from '../../common/sysco-ui-components/config/constant';

const { Option } = Select;

class VendorLogin extends Component {
  static displayName = 'VendorLogin';

  constructor(props) {
    super(props);
    this.state = {
      errorMsg: '',
      unauth: false,
      serviceFailed: false,
      inactiveVendor: false,
      loading: false,
      accountLocked: false,
      rmeNumber: null,
      userEmail: null,
      vendorNumbers: [],
      vendorNumbersLoading: true
    }
  }

  getUserEmail = () => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        let email = user.username.replace("supplierwebsso_", "");
        this.setState({ userEmail: email });
        if (email)
          this.getVendorNumbers(email);
      })
      .catch(err => {
        console.log('Error getting user email.')
        this.redirectToSupplierSuite();
      });
  }

  getVendorNumbers = (email) => {
    Axios({
      url: `/get-vendor-numbers`,
      method: "POST",
      data: {
        "email": email
      }
    }).then(response => {
      if (response.data.rme_vendors) {
        let vendors = response.data.rme_vendors;
        this.setState({ vendorNumbers: vendors })
        if (vendors.length === 1){
          this.setState({ rmeNumber: vendors[0] })
          cookie.save("changeVendorNumbers", false, {
            path: "/",
          });
        }
        else
          cookie.save("changeVendorNumbers", true, {
            path: "/",
          });
      }
      this.setState({ vendorNumbersLoading: false })
    }).catch(err => {
      console.log('Error getting vendor numbers.')
      this.setState({ vendorNumbersLoading: false })
    })
  }

  redirectToSupplierSuite = () => {
    let env = environment();
    if (env === "-dev")
      window.location.href = SUPPLIER_SUITE_URL.DEV;
    else if (env === "-stg")
      window.location.href = SUPPLIER_SUITE_URL.STG;
    else
      window.location.href = SUPPLIER_SUITE_URL.PRD;
  }

  componentDidMount() {
    cookie.save(LOGIN_TYPE, LOGIN_SSS, {
      path: "/",
    });
    toast.dismiss()
    store.dispatch({ type: PORTAL_MESSAGE })
    this.setState({ unauth: false })
    document.getElementById("headerMain").classList.add("loginclass");
    document.getElementById("antTab").classList.add("loginclass");
    setTimeout(this.getUserEmail, 3000);
  }

  componentWillReceiveProps = (newProps) => {
    this.setState({ loading: false })
    if (newProps.ctr.loginFailed)
      this.setState({ unauth: true });
    if (newProps.ctr.serviceFailed)
      this.setState({ serviceFailed: true });
    if (newProps.ctr.inactiveVendor)
      this.setState({ inactiveVendor: true });
    if (newProps.ctr.accountLocked)
      this.setState({ accountLocked: true });
  }

  loginAction = () => {
    if (!this.state.rmeNumber) {
      return
    }
    this.setState({ errorMsg: "", loading: true })
    let userName = this.state.rmeNumber;

    cookie.save("userName", userName, {
      path: "/",
    });
    localStorage.setItem("vendorNumber", userName)
    let errorMsg = '';
    this.setState({ errorMsg: errorMsg }, () => {
    });
    if (errorMsg.length === 0) {
      this.setState({ unauth: false });
      this.props.OnLogin(userName);
    } else
      this.setState({ loading: false })
  }

  handleRmeNumberChange = (value) => {
    this.setState({ rmeNumber: value });
  };

  render() {

    let errorDiv = null;
    if (this.state.errorMsg.length > 0)
      errorDiv = (<div className="errorMsg">{this.state.errorMsg}</div>);
    else if (this.state.loginFail)
      errorDiv = (<div className="errorMsg">{INCORRECT_MESSAGE}</div>);
    else if (this.state.unauth)
      errorDiv = (<div className="errorMsg">{UNAUTHORIZED_MESSAGE}</div>);
    else if (this.state.inactiveVendor)
      errorDiv = (<div className="errorMsg">{INACTIVEVENDOR_MESSAGE}</div>);
    else if (this.state.serviceFailed)
      errorDiv = (<div className="errorMsg">{NETWORK_ERROR}</div>);

    return (
      <div className="background-img" >

        <div className="loginBody">

          <div style={{ display: "inline-block" }}><div className="sysco_logo"><img className="head_img" src={favicon} alt="sysco" /><div style={{ float: "left" }}></div></div>
            <div className="spanT">SMS Supplier Payables </div></div>

          <div className="loginCard">
            {errorDiv}
            <div className="inputsSection">
              <Spin spinning={this.state.vendorNumbersLoading}>
                <Form id="loginForm">
                  {this.state.vendorNumbers.length === 1 ?
                    (<Select style={{ width: "100%" }} value={this.state.rmeNumber} disabled={true} />) :
                    (<Select style={{ width: "100%" }} placeholder="Select your Vendor Number" onChange={this.handleRmeNumberChange}>
                      {
                        this.state.vendorNumbers.map((item) => (
                          <Option key={item.id} value={item}>
                            {item}
                          </Option>
                        ))
                      }
                    </Select>)
                  }
                  <div >
                    <Button style={{ width: "100%", background: "#50b737", border: "#50b737" }} icon={<LoginOutlined />} disabled={!this.state.rmeNumber} type="primary" onClick={this.loginAction}>
                      LOGIN
                    </Button>

                  </div>
                </Form>
              </Spin>
            </div>
          </div>
          <div className="loginMsg" >
            <div style={{ backgroundColor: "rgb(12, 105, 160)", marginBottom: "5px", marginTop: "3px" }}>
              <div style={{ marginLeft: "10px" }}>Quick links</div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ marginLeft: "10px", display: "flex", alignItems: "center" }}><PlayCircleTwoTone /><a href="https://view.vzaar.com/21463864/player" target="_blank" rel="noopener noreferrer" style={{ padding: "4px", color: "black" }}>Supplier Payables training video</a></div>

            </div>
            <div style={{ backgroundColor: "rgb(81, 186, 56)", marginBottom: "5px", marginTop: "10px" }}>
              <div style={{ marginLeft: "10px" }}>Message from SYSCO ({(this.props.dashboardReducer.loginMsg && this.props.dashboardReducer.loginMsg.lastModifiedDate) ? moment(this.props.dashboardReducer.loginMsg.lastModifiedDate).format("MM/DD/YYYY") : ""})</div>
            </div>
            <div style={{ marginLeft: "10px", color: "black", fontSize: "12px" }}>{this.props.dashboardReducer.loginMsg ? unescape(this.props.dashboardReducer.loginMsg.loginMessage) : ''}</div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dashboardReducer: state.DashboardReducer,
    ctr: state.loginReducer
  };
}
const mapDispatchToProps = dispatch => {
  return {
    OnLogin: (username) => { dispatch(authSss(username)); }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorLogin);